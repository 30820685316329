import { Link } from '@remix-run/react';
import { Menu } from '@headlessui/react';
import Icon from '~/components/Icon';
import Svg from '~/components/Svg';
import { classList } from '~/utilities/css';
import { useAuth } from '~/context/useAuth';

function ContextNav() {
	const auth = useAuth();

	return (
		<div className="uic-flex uic-items-center uic-justify-between uic-bg-sky uic-h-full uic-pl-20 uic-pr-8 uic-min-w-[200px]">
			<Svg name="bv-logo" className="uic-fill-white uic-w-[142px]" />

			<Menu as="div" className="uic-h-20">
				{' '}
				{/* Match icon size */}
				<Menu.Button aria-label="Open context navigation">
					{({ open }) => (
						open ? (
							<Icon
								className="uic-w-20 uic-h-20 uic-text-white"
								name="chevron-up"
							/>
						) : (
							<Icon
								className="uic-w-20 uic-h-20 uic-text-white"
								name="chevron-down"
							/>
						)
					)}
				</Menu.Button>
				<Menu.Items
					as="ul"
					className={classList([
						'uic-absolute',
						'uic-left-[0] uic-top-[50px]',
						'uic-text-xxs',
						'uic-bg-snow',
						'uic-px-20 uic-py-15',
						'uic-min-w-[250px]', // Magic number
						'uic-shadow-lg',
						'uic-border-1 uic-border-[#e0e0e0]',
					])}
				>
					<Menu.Item as="li">
						<strong>Help</strong>
					</Menu.Item>

					<Menu.Item as="li">
						<Link
							to="https://www.brandverity.com/contact-us/"
							className="hover:uic-cursor-pointer"
							reloadDocument
						>
							Help/Feedback
						</Link>
					</Menu.Item>

					{auth.user?.userType?.identifier === 'internal' ? (
						<>
							<Menu.Item as="li" className="uic-mt-15">
								<strong>Admin</strong>
							</Menu.Item>

							<Menu.Item as="li">
								<Link
									to="/admin/email-templates"
									className="hover:uic-cursor-pointer"
									reloadDocument
								>
									Email templates
								</Link>
							</Menu.Item>

							<Menu.Item as="li">
								<Link
									to="/admin/users"
									className="hover:uic-cursor-pointer"
									reloadDocument
								>
									Users
								</Link>
							</Menu.Item>

							<Menu.Item as="li">
								<Link
									to="/admin/clients"
									className="hover:uic-cursor-pointer"
									reloadDocument
								>
									Clients
								</Link>
							</Menu.Item>

							<Menu.Item as="li">
								<Link
									to="/admin/publishers"
									className="hover:uic-cursor-pointer"
									reloadDocument
								>
									Publishers
								</Link>
							</Menu.Item>

							<Menu.Item as="li">
								<Link
									to="/admin/policies"
									className="hover:uic-cursor-pointer"
									reloadDocument
								>
									Policies
								</Link>
							</Menu.Item>
						</>
					) : null}

					<Menu.Item as="li" className="uic-flex uic-items-center uic-mt-15">
						<span>
							{auth.user?.email}
						</span>

						<span className="uic-inline-block uic-m-4">-</span>

						<Link
							to="/logout"
							className="hover:uic-cursor-pointer"
							style={{
								// TODO: figure out why uic-underline isn't compiling
								textDecoration: 'underline',
							}}
							reloadDocument
						>
							Logout
						</Link>
					</Menu.Item>
				</Menu.Items>
			</Menu>
		</div>
	);
}

export { ContextNav as default, ContextNav };
