export type Link = {
	label: string,
	local?: boolean,
	url: string,
	href?: string
};

export type NestedLink = {
	label: string,
	baseUrl?: string,
	items: Link[]
};

export type Links = (Link | NestedLink)[];

// Type narrowing with "type predicates"
// Verifying unique keys since neither should have both. We don't technically need both checks since TS knows based on
// the union for link BUT it is just a little more readable.
export function isLink(link: Link | NestedLink): link is Link {
	return (link as Link).url !== undefined;
}

export function isNestedLink(link: NestedLink | Link): link is NestedLink {
	return (link as NestedLink).items !== undefined;
}

export const links: Links = [
	{
		label: 'Dashboard',
		url: '/dashboard',
		local: true,
	},
	{
		label: 'Partner Alerts',
		url: '/partner-alerts',
		local: true,
	},
	{
		label: 'Alerts',
		url: '/alerts',
		local: true,
	},
	{
		label: 'Policies',
		url: '/policies',
		local: true,
	},
	{
		label: 'Templates',
		url: '/email-templates',
		local: true,
	},
];
